<template>
    <div class="p-16 shadow mb-10" style="background-color:#F8F8F8">
        <p class="text-3xl mb-10 leading-normal">Hier können Sie neue Tickets schreiben</p>
        <form @submit.prevent="submitForm">
            <div class="form-control">
                <label>Betreff:</label>
                <input type="text" v-model="subject" autocomplete="off">
            </div>
            <div class="form-control">
                <label>Name:</label>
                <input type="text" v-model="name" autocomplete="off">
            </div>
            <div class="form-control">
                <label>E-Mail:</label>
                <input type="text" v-model="email" autocomplete="off">
            </div>
            <details class="text-xl">
                    <summary>Shortcuts</summary>
                    Use:
                    ctrl+b for <b>bold</b>
                    ctrl+u for <u>underline</u>
                    ctrl+i for <i>italics</i>
                </details>
            <v-select
                class="filter-white max-w-3xl text-2xl w-full mb-5"
                placeholder="Makro ausführen"
                :options="makrosTitles"
                position="top"
                @input="updateValue"
            ></v-select>
            <div class="border-2 relative cursor-pointer border-grey-400">
                <vue-dropzone 
                        ref="myVueDropzone"
                        id="dropzone"
                        @vdropzone-success="addedFile"
                        :options="dropzoneOptions"
                    ></vue-dropzone>
                <!-- <editor
                    apiKey="8spqxq12m8z598gogaydst2muefa6ygx32kigzgt05bazbbp"
                    placeholder="Hier schreiben"
                    :value="selected"
                    @input="updateContent($event)"
                    :init="{
                    height: 324,
                    menubar: false,
                    branding: false,
                    toolbar_location: 'bottom',
                    resize: true,
                    skin: 'naked',
                    ref: 'editori',
                    statusbar: false,
                    contextmenu: false,
                    plugins: [
                    'advlist autolink lists link preview anchor',
                    'code fullscreen',
                    'paste code contextmenu',
                    ],
                    toolbar:
                    'undo redo formatselect link bold italic backcolor forecolor \
                        alignleft aligncenter alignright alignjustify \
                        bullist numlist outdent indent \
                        fullscreen',
                    }"
                >
                </editor> -->
                <div>
                    <TextEditor @dragover="checkDrop" @input="updateContent($event)" placeholder="Antwort hier schreiben" v-model="selected"></TextEditor>
                </div>
                <button type="button" class="show-files" @click="toggleDropzone">{{ `Abgelegte Dateien ${!showDrop ? 'anzeigen' : 'ausblenden'}` }}</button>
                <button class="file-btn" type="button" @click="uploadFiles" />  
            </div>
            <p class="text-2xl mt-10 text-red-400" v-if="error">{{ error }}</p>
            <button type="submit" class="mt-10 primary-btn">Senden</button>
        </form>
    </div>
</template>

<style lang="scss" scoped>
    .editor {
        border: 1px solid gray;
        font-size: 15px;
        width: 100%;
        height: 200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        outline: none;
        overflow-y: auto;
        cursor: text;
    }
    .vue-dropzone {
        padding: 0px;
    }
    .dz-message {
        margin: 0px !important;
    }
    #dropzone {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0;
        pointer-events: none;
    }
    .show-files {
        position: absolute;
        bottom: -3rem;
        right: 0;
        font-size: 1.3rem;
        color: rgb(79, 152, 193);
        text-decoration: underline;
        &:focus {
            outline: none;
        }
    }
    .tox .tox-editor-header {
        z-index: 0 !important;
    }
    .file-btn {
        position: absolute;
        bottom: 1rem;
        right: 0;
        z-index: 2;

    }
    .file-btn {
        cursor: pointer;
        width: 180px;
        height: 34px;
        overflow: hidden;
    }

    .file-btn:before {
        width: 158px;
        height: 32px;
        font-size: 18px;
        line-height: 32px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f0c6";
        color: #6b6f75;
        display: inline-block;
        text-align: right;
        padding-top: 8px;
        transition: color .5s ease;
    }

    .file-btn:hover:before {
        color: #b4bcc7;
    }

    .file-btn:focus {
        outline: none;
    }
</style>

<script>
    import TextEditor from '../components/TextEditor.vue';
    // import Editor from '@tinymce/tinymce-vue';
    import { mapActions, mapGetters } from 'vuex';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        data() {
            return {
                files: '',
                filesDropzone: '',
                contentValue: '',
                subject: '',
                error: '',
                email: '',
                name: '',
                selected: '',
                showDrop: false,
                dropzoneOptions: {
                // url: process.env.baseURL + '/api/v1/uploads',
                url: 'https://ourdesk.io/api/v1/uploads',
                maxFilesize: 10,
                headers: { 'My-Awesome-Header': 'header value' },
                addRemoveLinks: true,
                dictDefaultMessage: 'Datei hochladen',
                dictRemoveFile: 'Datei entfernen'
                },
                content: '',
            }
        },
        mounted() {
            this.getMakros();
        },
        components: {
            // editor: Editor,
            TextEditor,
            vueDropzone: vue2Dropzone
        },
        methods: {
            ...mapActions(['writeTicket', 'getMakros']),
            handleFilesUpload() {
                this.files = this.$refs.files.files;
            },
            updateContent(value) {
                this.selected = value;
                this.contentValue = value;
            },
            submitForm() {
                if(this.subject && this.contentValue) {
                    const fileData = new FormData();
                    // for(let i = 0; i < this.files.length; i++) {
                    //     fileData.append('files', this.files[i]);
                    // }
                    if(this.filesDropzone.length) {
                        for(let i = 0; i < this.filesDropzone.length; i++) {
                            fileData.append('files', this.filesDropzone[i]);
                        }   
                    }
                    fileData.append('comment', this.contentValue);
                    fileData.append('subject', this.subject);
                    fileData.append('name', this.name);
                    fileData.append('email', this.email);
                    fileData.append('tag', window.localStorage.getItem('favorite'));
                    this.writeTicket(fileData)
                } else {
                    this.error = 'Daten müssen ausgefüllt werden'
                }
                
            },
            withPopper (dropdownList, component, {width}) {
                dropdownList.style.width = width;
                const popper = createPopper(component.$refs.toggle, dropdownList, {
                    placement: 'top',
                    modifiers: [
                    {
                        name: 'offset', options: {
                            offset: [0, -1]
                        }
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn ({state}) {
                            component.$el.classList.toggle('drop-up', state.placement === 'top')
                        },
                    }]
                });
                return () => popper.destroy();
            },
            updateValue(value) {
                if(value) {
                    this.selected += this.makros.filter(el => el.title == value)[0].content;
                    this.contentValue = this.selected;
                }
            },
            toggleDropzone() {
                this.showDrop = !this.showDrop;
                if(this.showDrop) {
                    document.querySelector("#dropzone").style.opacity = 1;
                    document.querySelector("#dropzone").style.pointerEvents = 'all';
                } else {
                    document.querySelector("#dropzone").style.opacity = 0;
                    document.querySelector("#dropzone").style.pointerEvents = 'none';
                }
            },
            addedFile(file) {
                if(Array.isArray(this.filesDropzone)) {
                    this.filesDropzone.push(file);
                } else {
                    this.filesDropzone = [file];
                }
                document.querySelector("#dropzone").style.opacity = 0;
                document.querySelector("#dropzone").style.pointerEvents = 'none';
            },
            uploadFiles() {
                document.querySelector("#dropzone").click();
            },
            
        },
        computed: {
            ...mapGetters(['makros']),
            makrosTitles() {
                return this.makros.map(el => el.title);
            },
        }
    }
</script>